import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { t } from '../../../../src/common/i18n'
import LandingInvierno from '../../static/landing-invierno'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/pages/landing.module.scss'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.invierno'),
        },
      ]}>
      <LandingInvierno styles={styles} data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingInviernoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    imagenes_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/imagenes/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/imagenes/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/imagenes/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/imagenes/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/banners/ca/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/banners/es/desktop/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/banners/ca/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: {
        Key: { glob: "landings/estacion/invierno/banners/es/mobile/*" }
      }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
