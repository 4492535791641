import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import ResponsiveImages from '../../../src/common/components/ResponsiveImages/ResponsiveImages'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { locale } from '../../../src/common/i18n'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import route from '../../../src/common/utils/route'
import { getShortCompany } from '../../../src/common/utils/getCompany'
import { SeoData } from '../../../src/common/types/Seo'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}

interface InSeoData {
  seoData: SeoData
}

interface Props {
  styles: { [k: string]: string }
  data: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}
const urls: { id: number; idVideo: string }[] = [
  {
    id: 1,
    idVideo: 'eSgCdE5qyjg',
  },
  {
    id: 2,
    idVideo: 'RuHT1ZYqESg',
  },
  {
    id: 3,
    idVideo: '33rLI_SEvD4',
  },
  {
    id: 4,
    idVideo: 'x0H0Msey0dE',
  },
  {
    id: 5,
    idVideo: 'w2atu-WqXJE',
  },
]

const LandingInviernoEs = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos de invierno" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Cuando llega el frio las condiciones de la carretera cambian. Si la
          <strong>
            {' '}
            temperatura desciende por debajo de los 7ºC, el neumático estándar
            empieza a perder eficacia y agarre al endurecerse su goma.
          </strong>{' '}
          El neumático de invierno está diseñado especialmente para ser más
          eficiente en bajas temperaturas, carreteras mojadas, heladas o
          nevadas... ofreciendo las mejores prestaciones en todas estas
          situaciones.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>Sin necesidad de utilizar cadenas </h2>
          <p>
            Los neumáticos de inverno son la mejor alternativa para evitar el
            uso de cadenas en situación de nieve o hielo. En España los
            componentes de estos neumáticos están permitidos por ley para su uso
            en esta clase de situaciones. Los neumáticos de invierno te{' '}
            <strong>evitan la incomodidad que ofrecen las cadenas</strong> que
            solo están destinadas para ser usadas en momento puntuales de mucha
            nieve o terreno helado. Con neumáticos de invierno{' '}
            <strong>evitarás montar y desmontar </strong>cadenas
            independientemente de las condiciones, anulando también la
            posibilidad de sufrir imprevistos a causa de despiste.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            ¿Cuándo debo realizar el cambio de neumáticos a neumáticos de
            invierno?
          </h2>
          <p>
            Con la llegada del frio durante los meses de septiembre y octubre
            muchos conductores deciden realizar el cambio a neumáticos de
            inverno. En los <strong>talleres {getShortCompany()} </strong>
            disponemos de <strong>servicio de guarda neumáticos</strong> donde
            nos encargamos de almacenar tus ruedas hasta que necesites realizar
            un nuevo cambio. Cuando las temperaturas sobrepasan los 18ºC los
            neumáticos de invierno se ablandan sufriendo un mayor desgaste,
            llegado este momento se tendrá que proceder a colocar las ruedas de
            verano.
          </p>
          <p>
            Los neumáticos de inviernos están también disponibles para
            <strong> vehículos 4x4 y furgonetas. </strong>
          </p>
        </section>
        <div className={styles.player_wrapper}>
          {urls.map((item) => (
            <iframe
              className={styles.react_player}
              style={{ height: '300px' }}
              key={item.id}
              id="player1_youtube_iframe"
              frameBorder="0"
              allowFullScreen={true}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="YouTube video player"
              width="100%"
              height="300px"
              src={`https://www.youtube.com/embed/${item.idVideo}?controls=1&amp;rel=0&amp;disablekb=1&amp;showinfo=0&amp;modestbranding=0&amp;html5=1&amp;iv_load_policy=3&amp;autoplay=0&amp;end=0&amp;loop=0&amp;playsinline=1&amp;start=0&amp;nocookie=false&amp;enablejsapi=1&amp;origin=${process.env.GATSBY_WEB_URL}&amp;widgetid=3`}></iframe>
          ))}
        </div>
      </article>
    </div>
  )
}
const LandingInviernoCa = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  const [image_mobile] = getImages(
    data[`imagenes_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const [image_desktop] = getImages(
    data[`imagenes_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <div className={styles.container}>
      <Title text="Pneumàtics d'hivern" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          Quan arriba el fred les condicions de la carretera canvien. Si la
          <strong>
            {' '}
            temperatura baixa per sota dels 7ºC, el pneumàtic estàndard comença
            a perdre eficàcia i adherència{' '}
          </strong>
          perquè s’endureix la seva goma. El pneumàtic d'hivern està dissenyat
          especialment per a ser més eficient en baixes temperatures, carreteres
          mullades, gelades o nevades ... oferint les millors prestacions en
          totes aquestes situacions.
        </p>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_home'}
        />
        <section className={styles.section}>
          <h2>Sense necessitat d'utilitzar cadenes </h2>
          <p>
            Els pneumàtics d'hivern són la millor alternativa per evitar l'ús de
            cadenes en situació de neu o gel. A Espanya els components d'aquests
            pneumàtics estan permesos per llei per al seu ús en aquesta mena de
            situacions. Els pneumàtics d'hivern{' '}
            <strong>estalvien la incomoditat que ofereixen les cadenes</strong>{' '}
            que només estan destinades per a ser utilitzades en moments puntuals
            de molta neu o terreny gelat. Amb pneumàtics d'hivern
            <strong> evitaràs muntar i desmuntar </strong>cadenes
            independentment de les condicions, anul·lant també la possibilitat
            de patir imprevistos a causa d'una distracció.
          </p>
        </section>
        <ResponsiveImages
          imageDesktop={image_desktop}
          imageMobile={image_mobile}
        />
        <section className={styles.section}>
          <h2>
            Quan he de realitzar el canvi de pneumàtics a pneumàtics d'hivern?{' '}
          </h2>
          <p>
            Amb l'arribada del fred durant els mesos de setembre i octubre molts
            conductors decideixen fer el canvi a pneumàtics d'hivern. Als
            <strong> tallers Rodi </strong>disposem de
            <strong> servei de guarda pneumàtics </strong>on ens encarreguem
            d'emmagatzemar les teves rodes fins que necessitis realitzar un nou
            canvi. Quan les temperatures sobrepassen els 18ºC els pneumàtics
            d'hivern s'estoven patint un desgast superior, arribat aquest moment
            s'haurà de procedir a col·locar les rodes d'estiu.
          </p>
          <p>
            Els pneumàtics d'hiverns estan també disponibles per a
            <strong> vehicles 4x4 i furgonetes.</strong>{' '}
          </p>
        </section>
        <div className={styles.player_wrapper}>
          {urls.map((item) => (
            <iframe
              className={styles.react_player}
              style={{ height: '300px' }}
              key={item.id}
              id="player1_youtube_iframe"
              frameBorder="0"
              allowFullScreen={true}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              title="YouTube video player"
              width="100%"
              height="300px"
              src={`https://www.youtube.com/embed/${item.idVideo}?controls=1&amp;rel=0&amp;disablekb=1&amp;showinfo=0&amp;modestbranding=0&amp;html5=1&amp;iv_load_policy=3&amp;autoplay=0&amp;end=0&amp;loop=0&amp;playsinline=1&amp;start=0&amp;nocookie=false&amp;enablejsapi=1&amp;origin=${process.env.GATSBY_WEB_URL}&amp;widgetid=3`}></iframe>
          ))}
        </div>
      </article>
    </div>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-invierno'),
        temporada: { selectable: false, value: 'invierno' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'invierno',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer
          showMarcas={false}
          origin={route('landing-invierno')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const LandingInvierno = ({ ...props }: Props) =>
  locale === 'es' ? (
    <LandingInviernoEs {...props} />
  ) : (
    <LandingInviernoCa {...props} />
  )

export default LandingInvierno
